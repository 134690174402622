var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "createscd"
  }, [_c('r-box', {
    staticClass: "createscd--formbox mb-9"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('label', {
    staticClass: "d-block mb-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('dashboards.createDashboard.selectCategory')) + " ")]), _c('r-select', {
    staticClass: "createscd--rselect",
    attrs: {
      "items": _vm.items,
      "infinite": "",
      "show-search": true,
      "allow-empty": true
    },
    on: {
      "on:get-more": function ($event) {
        return _vm.onGetMoreItems(true);
      },
      "change": _vm.onSelectCategory,
      "change:search": _vm.onSearchChange
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-divider', {
    staticClass: "my-4"
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('r-text-field', {
    attrs: {
      "value": _vm.name,
      "label": _vm.$t('dashboards.createDashboard.name')
    },
    on: {
      "change": _vm.onChangeDashboardName
    }
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "d-inline-flex align-center justify-end",
    attrs: {
      "cols": "5"
    }
  }, [_c('div', [_c('label', {
    staticClass: "createscd--template d-block pb-4"
  }, [_vm._v("Selected Template")]), _c('r-dashboard-type-card', {
    attrs: {
      "type": _vm.TYPES.SINGLE_CATEGORY,
      "label": "Single Category Deepdive"
    }
  })], 1)])], 1)], 1), _c('r-error-message', {
    attrs: {
      "errors": [_vm.fetchCreateDashboardError]
    }
  }), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', [_c('div', {
    staticClass: "d-flex align-center justify-start mt-3"
  }, [_c('span', {
    staticClass: "createscd--back-link",
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }, [_vm._v(" Back ")])])]), _c('v-col', [_c('div', {
    staticClass: "d-flex align-center justify-end"
  }, [_c('r-button', {
    staticClass: "mr-2",
    attrs: {
      "outlined": true,
      "label": _vm.$t('global.actions.cancel')
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('cancel');
      }
    }
  }), _c('r-button', {
    attrs: {
      "label": _vm.$t('dashboards.createDashboard.createDashboardBtn'),
      "disabled": _vm.isCreateDashboardBtnDisabled,
      "loading": _vm.fetchingCreateDashboard
    },
    on: {
      "click": _vm.onCreateDashboard
    }
  })], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }