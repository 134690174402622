<template>
  <div class="createsbd">
    <r-box class="createsbd--formbox mb-9">
      <v-row>
        <v-col cols="7">
          <v-row>
            <v-col cols="12">
              <label class="d-block mb-1">
                {{ $t('dashboards.createDashboard.selectBrand') }}
              </label>
              <r-select
                class="createsbd--rselect"
                :items="items"
                infinite
                :show-search="true"
                :allow-empty="true"
                @on:get-more="onGetMoreItems(true)"
                @change="onSelectBrand"
                @change:search="onSearchChange"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-divider class="my-4" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <r-text-field
                :value="name"
                :label="$t('dashboards.createDashboard.name')"
                @change="onChangeDashboardName"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col
          cols="5"
          class="d-inline-flex align-center justify-end "
        >
          <div>
            <label class="createsbd--template d-block pb-4">Selected Template</label>
            <r-dashboard-type-card
              :type="TYPES.SINGLE_BRAND"
            />
          </div>
        </v-col>
      </v-row>
    </r-box>
    <r-error-message :errors="[fetchCreateDashboardError]" />
    <v-row class="mt-3">
      <v-col>
        <div class="d-flex align-center justify-start mt-3">
          <span
            class="createsbd--back-link"
            @click="$emit('back')"
          >
            Back
          </span>
        </div>
      </v-col>
      <v-col>
        <div class="d-flex align-center justify-end">
          <r-button
            class="mr-2"
            :outlined="true"
            :label="$t('global.actions.cancel')"
            @click="$emit('cancel')"
          />
          <r-button
            :label="$t('dashboards.createDashboard.createDashboardBtn')"
            :disabled="isCreateDashboardBtnDisabled"
            :loading="fetchingCreateDashboard"
            @click="onCreateDashboard"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RBox from '@/components/library/molecules/RBox'
import RButton from '@/components/library/atoms/RButton'
import RTextField from '@/components/library/molecules/RTextField'
import RSelect from '@/components/library/molecules/RSelect'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import { mapActions, mapState, mapGetters } from 'vuex'
import { TYPES_OF_DASHBOARD } from '@/utils/constants'
import { generateParams } from '@/utils/store'
import RDashboardTypeCard from './common/RDashboardTypeCard'

export default {
  name: 'CreateSingleBrandDashboard',
  components: {
    RBox,
    RButton,
    RSelect,
    RTextField,
    RDashboardTypeCard,
    RErrorMessage,
  },
  data: () => ({
    name: '',
    brandId: null,
    searchValue: '',
  }),
  computed: {
    ...mapGetters('projects', [
      'selectedProjectId',
    ]),
    ...mapState('brands', [
      'brandsSummary',
    ]),
    ...mapState('dashboards', [
      'fetchingCreateDashboard',
      'fetchCreateDashboardError',
    ]),
    items() {
      return this.brandsSummary.map(({ _id, name }) => ({
        label: name,
        value: _id,
        selected: false,
      }))
    },
    isCreateDashboardBtnDisabled() {
      return !(this.name && this.brandId)
    },
  },
  watch: {
    selectedProjectId() {
      this.getBrands()
    },
  },
  created() {
    this.TYPES = TYPES_OF_DASHBOARD
  },
  mounted() {
    this.getBrands()
  },
  methods: {
    ...mapActions('brands', [
      'fetchBrandsSummary',
    ]),
    getBrands() {
      this.fetchBrandsSummary({ projectId: this.selectedProjectId })
    },
    onChangeDashboardName({ value }) {
      this.name = value
    },
    onSelectBrand({ value }) {
      this.brandId = value
    },
    async onCreateDashboard() {
      this.$emit('create', {
        name: this.name,
        brandIds: [this.brandId],
      })
    },
    onGetMoreItems(isInfinityScroll = false) {
      let params = {
        projectId: this.selectedProjectId,
        isInfinityScroll,
        search: this.searchValue,
      }
      if (isInfinityScroll) {
        const pageParams = generateParams('brands')

        if (!pageParams) {
          return undefined
        }
        params = { ...params, ...pageParams }
        return this.fetchBrandsSummary(params)
      }
      return this.fetchBrandsSummary(params)
    },
    onSearchChange({ value }) {
      this.searchValue = value
      this.fetchBrandsSummary({ projectId: this.selectedProjectId, search: value, page: 1 })
    },
  },
}
</script>

<style scoped>
.createsbd--formbox {
  box-shadow: none;
}

.createsbd--template {
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
}

.createsbd--back-link {
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.03px;
  color: var(--primary-color);
}
</style>
