var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "productstable"
  }, [_c('r-box', {
    staticClass: "productstable--formbox mb-9"
  }, [_c('v-row', {
    staticClass: "ma-0 datalisting--searchcontainer",
    staticStyle: {
      "position": "relative",
      "top": "-8px"
    }
  }, [_c('v-col', {
    staticClass: "d-flex justify-start align-center pl-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('label', [_vm._v(_vm._s(_vm.$t('dashboards.createDashboard.selectProducts')))])]), _c('r-search-input', {
    staticClass: "mr-2 datalisting--search",
    staticStyle: {
      "width": "300px"
    },
    attrs: {
      "value": _vm.appliedFilters.search
    },
    on: {
      "change": _vm.onChangeSearch
    }
  }), _c('r-anchored-popup', {
    staticClass: "datalisting--filterbtn",
    attrs: {
      "label": _vm.$t('dataFilters.label'),
      "icon": "filter_list"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var onClose = _ref.onClose;
        return [_c('data-filters', {
          on: {
            "close": onClose
          }
        })];
      }
    }])
  })], 1), _c('r-data-filter-chips', {
    staticClass: "mb-2"
  }), _c('r-table', {
    key: "productstable",
    staticClass: "productstable--table",
    attrs: {
      "columns": _vm.tableColumns,
      "rows": _vm.mappedProducts,
      "total-rows": _vm.totalProducts,
      "page": _vm.page,
      "page-size": _vm.pageSize,
      "loading": _vm.fetchingProducts
    },
    on: {
      "change:page": _vm.setPage,
      "change:page-size": _vm.onChangePageSize,
      "change:page-size-all": _vm.onChangePageSizeAll,
      "sort:asc": function (_ref2) {
        var value = _ref2.value;
        return _vm.setSort({
          sort: value,
          sortDir: 'asc'
        });
      },
      "sort:desc": function (_ref3) {
        var value = _ref3.value;
        return _vm.setSort({
          sort: value,
          sortDir: 'desc'
        });
      },
      "change:batch-select": _vm.onProductsSelection,
      "change:batch-select-all": _vm.onProductsSelection
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('span', {
          staticClass: "productstable--name text-truncate"
        }, [_vm._v(_vm._s(item.name))])];
      }
    }, {
      key: "item.brands",
      fn: function (_ref5) {
        var item = _ref5.item;
        return _vm._l((item.brands || []).slice(0, 1), function (brand) {
          return _c('r-badge', {
            key: brand.name,
            attrs: {
              "name": brand.name,
              "color": brand.color
            }
          });
        });
      }
    }, {
      key: "item.numReviews",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "d-flex justify-start align-center"
        }, [_c('span', [_vm._v(_vm._s(item.numReviews))])])];
      }
    }, {
      key: "item.created",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(_vm.formatDate(item.created)) + " ")];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }