<template>
  <div class="createscd">
    <r-box class="createscd--formbox mb-9">
      <v-row>
        <v-col cols="7">
          <v-row>
            <v-col cols="12">
              <label class="d-block mb-1">
                {{ $t('dashboards.createDashboard.selectCategory') }}
              </label>
              <r-select
                class="createscd--rselect"
                :items="items"
                infinite
                :show-search="true"
                :allow-empty="true"
                @on:get-more="onGetMoreItems(true)"
                @change="onSelectCategory"
                @change:search="onSearchChange"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-divider class="my-4" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <r-text-field
                :value="name"
                :label="$t('dashboards.createDashboard.name')"
                @change="onChangeDashboardName"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col
          cols="5"
          class="d-inline-flex align-center justify-end "
        >
          <div>
            <label class="createscd--template d-block pb-4">Selected Template</label>
            <r-dashboard-type-card
              :type="TYPES.SINGLE_CATEGORY"
              label="Single Category Deepdive"
            />
          </div>
        </v-col>
      </v-row>
    </r-box>
    <r-error-message :errors="[fetchCreateDashboardError]" />
    <v-row class="mt-3">
      <v-col>
        <div class="d-flex align-center justify-start mt-3">
          <span
            class="createscd--back-link"
            @click="$emit('back')"
          >
            Back
          </span>
        </div>
      </v-col>
      <v-col>
        <div class="d-flex align-center justify-end">
          <r-button
            class="mr-2"
            :outlined="true"
            :label="$t('global.actions.cancel')"
            @click="$emit('cancel')"
          />
          <r-button
            :label="$t('dashboards.createDashboard.createDashboardBtn')"
            :disabled="isCreateDashboardBtnDisabled"
            :loading="fetchingCreateDashboard"
            @click="onCreateDashboard"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RBox from '@/components/library/molecules/RBox'
import RButton from '@/components/library/atoms/RButton'
import { TYPES_OF_DASHBOARD } from '@/utils/constants'
import RTextField from '@/components/library/molecules/RTextField'
import { mapActions, mapState, mapGetters } from 'vuex'
import RSelect from '@/components/library/molecules/RSelect'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import { generateParams } from '@/utils/store'
import RDashboardTypeCard from './common/RDashboardTypeCard'

export default {
  name: 'CreateSingleCategoryDashboard',
  components: {
    RBox,
    RButton,
    RSelect,
    RTextField,
    RDashboardTypeCard,
    RErrorMessage,
  },
  data: () => ({
    name: '',
    categoryId: null,
    searchValue: '',
  }),
  computed: {
    ...mapGetters('projects', [
      'selectedProjectId',
    ]),
    ...mapState('categories', [
      'categoriesSummary',
    ]),
    ...mapState('dashboards', [
      'fetchingCreateDashboard',
      'fetchCreateDashboardError',
    ]),
    items() {
      return this.categoriesSummary.map(({ _id, name }) => ({
        label: name,
        value: _id,
        selected: false,
      }))
    },
    isCreateDashboardBtnDisabled() {
      return !(this.name && this.categoryId)
    },
  },
  watch: {
    selectedProjectId() {
      this.getCategories()
    },
  },
  created() {
    this.TYPES = TYPES_OF_DASHBOARD
  },
  mounted() {
    this.getCategories()
  },
  methods: {
    ...mapActions('categories', [
      'fetchCategoriesSummary',
    ]),
    getCategories() {
      this.fetchCategoriesSummary({ projectId: this.selectedProjectId })
    },
    onChangeDashboardName({ value }) {
      this.name = value
    },
    onSelectCategory({ value }) {
      this.categoryId = value
    },
    async onCreateDashboard() {
      this.$emit('create', {
        name: this.name,
        categoryIds: [this.categoryId],
      })
    },
    onGetMoreItems(isInfinityScroll = false) {
      let params = {
        projectId: this.selectedProjectId,
        isInfinityScroll,
        search: this.searchValue,
      }
      if (isInfinityScroll) {
        const pageParams = generateParams('categories')

        if (!pageParams) {
          return undefined
        }
        params = { ...params, ...pageParams }
        return this.fetchCategoriesSummary(params)
      }
      return this.fetchCategoriesSummary(params)
    },
    onSearchChange({ value }) {
      this.searchValue = value
      this.fetchCategoriesSummary({ projectId: this.selectedProjectId, search: value, page: 1 })
    },
  },
}
</script>

<style scoped>
.createscd--formbox {
  box-shadow: none;
}

.createscd--template {
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
}

.createscd--back-link {
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.03px;
  color: var(--primary-color);
}
</style>
