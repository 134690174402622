<template>
  <div class="creatempd">
    <r-box
      v-if="step ===1"
      class="creatempd--formbox mb-9"
    >
      <v-row>
        <v-col cols="7">
          <v-row>
            <v-col cols="12">
              <r-text-field
                :value="name"
                :label="$t('dashboards.createDashboard.name')"
                placeholder="Samsung S10"
                @change="onChangeDashboardName"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="5"
          class="d-inline-flex align-center justify-end "
        >
          <div>
            <label class="creatempd--template d-block pb-4">Selected Template</label>
            <r-dashboard-type-card
              :type="TYPES.MULTI_PRODUCT"
              label="Multi Product Comparison"
            />
          </div>
        </v-col>
      </v-row>
    </r-box>
    <product-selection
      v-if="step === 2"
      @select:products="onSelectProducts"
    />
    <r-error-message :errors="[fetchCreateDashboardError, fetchProductsError]" />
    <v-row class="mt-3">
      <v-col>
        <div class="d-flex align-center justify-start mt-3">
          <span
            class="creatempd--back-link"
            @click="onBack"
          >
            Back
          </span>
        </div>
      </v-col>
      <v-col>
        <p class="mt-3">
          <b>
            <span :class="{'creatempd--stage-label': step === 2}">Set up</span>
            >
            <span :class="{'creatempd--stage-label': step === 1}">Selection</span>
          </b>
        </p>
      </v-col>
      <v-col>
        <div class="d-flex align-center justify-end">
          <r-button
            class="mr-2"
            :outlined="true"
            :label="$t('global.actions.cancel')"
            @click="$emit('cancel')"
          />
          <r-button
            v-if="step === 1"
            class="mr-2"
            :label="$t('global.actions.continue')"
            :disabled="isNameUndefined"
            @click="onContinue"
          />
          <r-button
            v-if="step === 2"
            class="mr-2"
            :disabled="isProductsPayloadUndefined"
            :label="$t('dashboards.createDashboard.createDashboardBtn')"
            :loading="fetchingCreateDashboard"
            @click="onCreateDashboard"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import RBox from '@/components/library/molecules/RBox'
import RButton from '@/components/library/atoms/RButton'
import { TYPES_OF_DASHBOARD } from '@/utils/constants'
import RTextField from '@/components/library/molecules/RTextField'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import RDashboardTypeCard from './common/RDashboardTypeCard'
import ProductSelection from './ProductSelection.vue'

export default {
  name: 'CreateMultiProductDashboard',
  components: {
    RBox,
    RButton,
    RTextField,
    RDashboardTypeCard,
    ProductSelection,
    RErrorMessage,
  },
  data: () => ({
    name: '',
    type: '',
    step: 1,
    productsPayload: {},
  }),
  computed: {
    ...mapState('dashboards', [
      'fetchingCreateDashboard',
      'fetchCreateDashboardError',
    ]),
    ...mapState('products', [
      'fetchProductsError',
      'products',
    ]),
    isNameUndefined() {
      return !this.name
    },
    isProductsPayloadUndefined() {
      const { productIds, allProductsSelected } = this.productsPayload
      return !((productIds && productIds.length > 1) || allProductsSelected)
    },
  },
  created() {
    this.TYPES = TYPES_OF_DASHBOARD
  },
  beforeMount() {
    const { type } = this.$route.params
    this.type = type
  },
  methods: {
    onChangeDashboardName({ value }) {
      this.name = value
    },
    onBack() {
      if (this.step === 1) { this.$emit('back') }
      this.step = 1
    },
    onContinue() {
      this.step = 2
    },
    async onCreateDashboard() {
      const { name, type, productsPayload: { productIds, productFilters } } = this
      this.$emit('create', {
        name, type, productIds, productFilters,
      })
    },
    onSelectProducts({ value }) {
      this.productsPayload = value
    },
  },
}
</script>

<style scoped>
.creatempd--formbox {
  box-shadow: none;
}

.creatempd--template {
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
}

.creatempd--back-link {
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.03px;
  color: var(--primary-color);
}

.creatempd--stage-label {
  color: var(--r-grey);
}
</style>
