import { render, staticRenderFns } from "./CreateSingleCategoryDashboard.vue?vue&type=template&id=3553d00c&scoped=true&"
import script from "./CreateSingleCategoryDashboard.vue?vue&type=script&lang=js&"
export * from "./CreateSingleCategoryDashboard.vue?vue&type=script&lang=js&"
import style0 from "./CreateSingleCategoryDashboard.vue?vue&type=style&index=0&id=3553d00c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3553d00c",
  null
  
)

export default component.exports