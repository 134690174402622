<template>
  <div class="productstable">
    <r-box class="productstable--formbox mb-9">
      <v-row
        class="ma-0 datalisting--searchcontainer"
        style="position:relative;top:-8px"
      >
        <v-col
          cols="12"
          class="d-flex justify-start align-center pl-0"
        >
          <label>{{ $t('dashboards.createDashboard.selectProducts') }}</label>
        </v-col>
        <r-search-input
          :value="appliedFilters.search"
          class="mr-2 datalisting--search"
          style="width:300px"
          @change="onChangeSearch"
        />
        <r-anchored-popup
          v-slot="{ onClose }"
          :label="$t('dataFilters.label')"
          icon="filter_list"
          class="datalisting--filterbtn"
        >
          <data-filters @close="onClose" />
        </r-anchored-popup>
      </v-row>
      <r-data-filter-chips class="mb-2" />
      <r-table
        key="productstable"
        class="productstable--table"
        :columns="tableColumns"
        :rows="mappedProducts"
        :total-rows="totalProducts"
        :page="page"
        :page-size="pageSize"
        :loading="fetchingProducts"
        @change:page="setPage"
        @change:page-size="onChangePageSize"
        @change:page-size-all="onChangePageSizeAll"
        @sort:asc="({ value }) => setSort({ sort: value, sortDir: 'asc' })"
        @sort:desc="({ value }) => setSort({ sort: value, sortDir: 'desc' })"
        @change:batch-select="onProductsSelection"
        @change:batch-select-all="onProductsSelection"
      >
        <template v-slot:item.name="{ item }">
          <span class="productstable--name text-truncate">{{ item.name }}</span>
        </template>
        <template v-slot:item.brands="{ item }">
          <r-badge
            v-for="brand in (item.brands || []).slice(0, 1)"
            :key="brand.name"
            :name="brand.name"
            :color="brand.color"
          />
        </template>
        <template v-slot:item.numReviews="{ item }">
          <div class="d-flex justify-start align-center">
            <span>{{ item.numReviews }}</span>
          </div>
        </template>
        <template v-slot:item.created="{ item }">
          {{ formatDate(item.created) }}
        </template>
      </r-table>
    </r-box>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import RBox from '@/components/library/molecules/RBox'
import RTable from '@/components/library/organisms/RTable'
import debounce from '@/utils/debounce'
import formatDate from '@/utils/formatDate'
import dateStrToDate from '@/utils/dateStrToDate'
import RSearchInput from '@/components/library/molecules/RSearchInput'
import RAnchoredPopup from '@/components/library/molecules/RAnchoredPopup'
import DataFilters from '@/components/app/data/DataFilters'
import RDataFilterChips from '@/components/library/atoms/RDataFilterChips'
import RBadge from '@/components/library/atoms/RBadge'

export default {
  name: 'ProductSelection',
  components: {
    RBox,
    RSearchInput,
    RAnchoredPopup,
    DataFilters,
    RTable,
    RDataFilterChips,
    RBadge,
  },
  data: () => ({
    tableColumns: [
      {
        key: 'name',
        label: 'Product Name',
        hideable: false,
        sortable: true,
        width: 200,
      },
      {
        key: 'brands',
        label: 'Brands',
        hideable: false,
        sortable: false,
        width: 150,
      },
      {
        key: 'numReviews',
        label: 'Reviews',
        hideable: false,
        sortable: true,
        width: 100,
      },
      {
        key: 'created',
        label: 'Creation date',
        hideable: false,
        sortable: true,
        width: 100,
      },
    ],
    page: 1,
    sort: 'name',
    sortDir: 'asc',
    pageSize: 10,
    debouncedFetchData: null,
    selectedProductIds: [],
    selectedProductsPerPage: {},
    mappedProducts: [],
  }),
  computed: {
    ...mapState('products', [
      'products',
      'fetchingProducts',
      'totalProducts',
    ]),
    ...mapGetters('projects', [
      'selectedProjectId',
    ]),
    ...mapState('filters', [
      'filterKey',
    ]),
    ...mapGetters('filters', [
      'appliedFilters',
    ]),
  },
  watch: {
    page() {
      this.$data.debouncedFetchData()
    },
    pageSize() {
      this.$data.debouncedFetchData()
    },
    sort() {
      this.$data.debouncedFetchData()
    },
    sortDir() {
      this.$data.debouncedFetchData()
    },
    search() {
      this.setPage({ value: 1 })
    },
    selectedProjectId() {
      this.$data.debouncedFetchData()
    },
    filterKey() {
      this.$data.debouncedFetchData()
    },
    products() {
      this.loadProducts()
    },
    appliedFilters() {
      this.setPage({ value: 1 })
    },
  },
  async beforeMount() {
    this.resetFilters()
  },
  mounted() {
    this.$data.debouncedFetchData = debounce(() => this.fetchData(), 10)
    this.fetchData()
  },
  methods: {
    ...mapActions('dashboards', [
      'createDashboard',
      'clearErrors',
    ]),
    ...mapActions('filters', [
      'resetFilters',
      'setFilters',
    ]),
    ...mapActions('products', [
      'fetchProducts',
    ]),
    onChangeSearch({ value }) {
      this.setFilters({ search: value })
    },
    fetchData() {
      const {
        page,
        pageSize,
        sort,
        sortDir,
        selectedProjectId,
        appliedFilters,
      } = this
      const payload = {
        page, pageSize, sort, sortDir, ...appliedFilters, projectId: selectedProjectId,
      }

      this.fetchProducts(payload)
    },
    setPage({ value }) {
      this.page = value
    },
    onChangePageSize({ value }) {
      this.setPage({ value: 1 })
      this.pageSize = value
    },
    onChangePageSizeAll() {
      this.setPage({ value: 1 })
      this.pageSize = this.totalProducts
    },
    setSort({ sort, sortDir }) {
      this.sort = sort
      this.sortDir = sortDir
    },
    formatDate(date) {
      return formatDate({
        $moment: this.$moment,
        date: dateStrToDate(date),
      })
    },
    onProductsSelection({ value }) {
      const allProductsSelected = typeof value === 'boolean' && value ? value : false
      const productIds = Array.isArray(value) ? value.map(({ _id }) => _id) : []

      this.$set(this.selectedProductsPerPage, this.page, productIds)
      if (allProductsSelected) {
        this.selectedProductsPerPage = {}
      }

      const { appliedFilters, selectedProductsPerPage } = this
      this.selectedProductIds = Object.values(selectedProductsPerPage).flat()
      const productIdsPayload = Object.keys(selectedProductsPerPage).length
        ? { productIds: this.selectedProductIds } : {}
      const filtersPayload = Object.values(appliedFilters).length
        ? { productFilters: appliedFilters } : {}

      const payload = {
        allProductsSelected,
        ...productIdsPayload,
        ...filtersPayload,
      }

      this.$emit('select:products', { value: payload })
    },
    loadProducts() {
      this.mappedProducts = this.products.map((product) => ({
        ...product,
        selected: Object.values(this.selectedProductsPerPage).flat().includes(product._id),
      }))
    },
  },
}
</script>

<style scoped>
.productstable--table {
  width: 100%;
  min-height: 600px;
}

.productstable--name {
  display: block;
  width: 168px;
  /* 200 minus padding of 32*/
}

.productstable--setup {
  color: var(--r-grey);
}

/deep/ .rcategoriesdropdown--create-category-button.rselectitem--row,
/deep/ .rtagsdropdown--create-tag-button.rselectitem--row,
/deep/ .rbrandsdropdown--create-brand-button.rselectitem--row {
  display: none;
}
</style>
